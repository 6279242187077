import { Link } from "gatsby"
import { navigate } from 'gatsby'
import React from "react"
import { Container, Row, Col } from 'reactstrap';
import Filterizr from 'filterizr';
import ScrollAnimation from 'react-animate-on-scroll';
import "../../node_modules/animate.css/animate.compat.css";
import { AwesomeButton } from "react-awesome-button";

class SectionGalleryGl extends React.Component {
  componentDidMount() {


     const options = {
        animationDuration: 0.5, // in seconds
        callbacks: {
          onFilteringStart: function() { },
          onFilteringEnd: function() { },
          onShufflingStart: function() { },
          onShufflingEnd: function() { },
          onSortingStart: function() { },
          onSortingEnd: function() { }
       },
         delay: 100,
  delayMode: 'progressive',
  easing: 'ease-out',
       filter: 'strony',
       layout: 'sameSize',
  gutterPixels: 0,
  filterOutCss: {
    opacity: 0,
    transform: 'scale(0.5)'
       },
  filterInCss: {
    opacity: 1,
    transform: 'scale(1)'
  },
      
      }

    const filterizr = new Filterizr(".filter-container", options);
  }

  excert(text){
    text = text.replace(/<\/?[^>]+(>|$)/g, "");
    text = text.substring(0,  50) + '...';
    return text;
    }

  clickonGallery(event) {
     [...document.getElementsByClassName("gallerytab")].forEach(
      (element, index, array) => {
       element.classList.remove("active");
      }
    );
     event.target.classList.toggle("active");
  }
  getShitContent(){
    let pierwsza = 0;
    let druga = 0;
    let trzecia = 0;
  const gals = this.props.gal.map((val, index) =>   {
      if(val.acf.kategoria === 'strony' && pierwsza < 3){
        pierwsza++;
      return (
        <Link to={"/portfolio/" + val.acf.slug}>
          
          <div className="filtr-item boxik" data-category={val.acf.kategoria}>
     

          <div className='gallery-img'>       
            <img key={index} src={val.acf.imgfeatured.localFile.url} alt={val.acf.imgfeatured.alt_text}></img>
          </div>

      

        </div>
        
        </Link>
      )} else if(val.acf.kategoria === 'sklepy' && druga < 3){
        druga++;
        return (
          <Link to={"/portfolio/" + val.acf.slug}><div className="filtr-item boxik" data-category={val.acf.kategoria}>
    
            <div className='gallery-img'>
          <img key={index} src={val.acf.imgfeatured.localFile.url} alt={val.acf.imgfeatured.alt_text}></img>
          </div>
        
          </div></Link>
        )} else if(val.acf.kategoria === 'aplikacje' && trzecia < 3){
          trzecia++;
          return (
            <Link to={"/portfolio/" + val.acf.slug}><div className="filtr-item boxik" data-category={val.acf.kategoria}>
              
              <div className='gallery-img'>
            <img key={index} src={val.acf.imgfeatured.localFile.url} alt={val.acf.imgfeatured.alt_text}></img>
            </div>
           </div></Link>
          )}
    }

       );
       return gals;
  }

  getShitContenten(){
    let pierwsza = 0;
    let druga = 0;
    let trzecia = 0;
  const gals = this.props.gal.map((val, index) =>   {
      if(val.acf.kategoria === 'strony' && pierwsza < 3){
        pierwsza++;
      return (
        <Link to={"/en/portfolio/" + val.acf.slug}><div className="filtr-item boxik" data-category={val.acf.kategoria}>
          
    
              <div className='gallery-img'>
            <img key={index} src={val.acf.imgfeatured.localFile.url} alt={val.acf.imgfeatured.acf.alt_en}></img>
            </div>
        
        </div></Link>
      )} else if(val.acf.kategoria === 'sklepy' && druga < 3){
        druga++;
        return (
          <Link to={"/en/portfolio/" + val.acf.slug}><div className="filtr-item boxik" data-category={val.acf.kategoria}>
            
           
              <div className='gallery-img'>
            <img key={index} src={val.acf.imgfeatured.localFile.url} alt={val.acf.imgfeatured.acf.alt_en}></img>
            </div>
          
          </div></Link>
        )} else if(val.acf.kategoria === 'aplikacje' && trzecia < 3){
          trzecia++;
          return (
            <Link to={"/en/portfolio/" + val.acf.slug}><div className="filtr-item boxik" data-category={val.acf.kategoria}>
              
         
              <div className='gallery-img'>
            <img key={index} src={val.acf.imgfeatured.localFile.url} alt={val.acf.imgfeatured.acf.alt_en}></img>
            </div>
        
            </div></Link>
          )}
    }

       );
       return gals;
  }

    render() {
      
      return (
          <>
          <Container>
            <Row className='gallery-head-row'>
            <h2 className='title'>PORTFOLIO<span className='dot'>.</span></h2>
            <div className='gallery-title-box'>
            {this.props.lang === 'en' ?
              <ul>
                <li className='tabtitle gallerytab active' onClick={(e) => { this.clickonGallery(e) }} data-filter="strony"> websites </li>
                <li className='tabtitle gallerytab' onClick={(e) => { this.clickonGallery(e) }} data-filter="sklepy"> online shops </li>
                <li className='tabtitle gallerytab' onClick={(e) => { this.clickonGallery(e) }} data-filter="aplikacje"> Apps </li>
              </ul>
                :
                <ul>
                  <li className='tabtitle gallerytab active' onClick={(e) => { this.clickonGallery(e) }} data-filter="strony"> Strony internetowe </li>
                  <li className='tabtitle gallerytab' onClick={(e) => { this.clickonGallery(e) }} data-filter="sklepy"> Sklepy internetowe </li>
                  <li className='tabtitle gallerytab' onClick={(e) => { this.clickonGallery(e) }} data-filter="aplikacje"> Aplikacje </li>
                </ul>
            }
            </div>
            </Row>
            </Container>
            <Container fluid={true}>
              <div className="filter-container">
                {this.props.lang === 'en' ? this.getShitContenten() :   this.getShitContent()}
              </div> 
              {this.props.lang === 'en' ?
              <div className='centeredbutton'>
                <AwesomeButton
                  type="primary"
                  onPress={() => {
                    navigate('/en/portfolio')
                  }}
                >
                  See more of our projects
                </AwesomeButton>
              </div>
              :
              <div className='centeredbutton'>
                <AwesomeButton
                  type="primary"
                  onPress={() => {
                    navigate('/portfolio')
                  }}
                >
                  Zobacz więcej naszych realizacji
                </AwesomeButton>
              </div>
              }
              </Container>
          </>
      );
    }
  }
  


export default SectionGalleryGl;
