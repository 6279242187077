import React from "react"
import Accordions from "../components/accordion";

import { Container, Row, Col } from 'reactstrap';



class SectionThree extends React.Component {

    render() {
      return (
        <div className="section3">
         <Container>
           <Row>
             <Col className='first-col' lg='6'>
             <h2 className="title">{this.props.sec3.section_3_title}</h2>
              <div className='featured'>
                  {this.props.lang === 'pl' ?
                  <div className='image'>
                  <img src={this.props.sec3.section_3_img.localFile.url} alt={this.props.sec3.section_3_img.alt_text}></img>
                </div>
                :
                this.props.oferta == 'custom' ?
                <div className='image'>
                  <img src={this.props.sec3.section_3_img}></img>
                </div>
                :
                <div className='image'>
                  <img src={this.props.sec3.section_3_img.localFile.url} alt={this.props.sec3.section_3_img.acf.alt_en}></img>
                </div>
                }
                <div className='text'>
                  <p>{this.props.sec3.section_3_text}</p>
                </div>
             </div>

             </Col>
             <Col className='second-col' lg='6'>
                <Accordions cards={this.props.sec3.section_3_list_rep}/>
             </Col>
           </Row>
         </Container>
        </div>
      );
    }
  }


export default SectionThree;
