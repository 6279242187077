import React from "react"
import { Container, Row, Col } from 'reactstrap';
import Accordiontabs from "../components/accordiontabs"; 



class SectionSix extends React.Component {
    render() {
      return (
        <div className="section6">
         <Container>
           <Row>
             <Col lg='6'>
               <div className='section-left'>
                 <h2 className='title'>{this.props.sec6.section_6_title}<span className='dot'>.</span></h2>
               </div>
               <Accordiontabs cards={this.props.sec6.section_6_list_rep}/>
             </Col>
             <Col lg='6'>
               <div className='section-right'>
                 <p className='text'>{this.props.sec6.section_6_text}</p>
                        <div className='featured-block'>
                          {this.props.lang === 'pl' ?
                        <div className='image'>
                        <img src={this.props.sec6.section_6_list_2_img.localFile.url} alt={this.props.sec6.section_6_list_2_img.alt_text}></img>
                      </div>
                      :
                      <div className='image'>
                            <img src={this.props.sec6.section_6_list_2_img.localFile.url} alt={this.props.sec6.section_6_list_2_img.acf.alt_en}></img>
                          </div>  
                        }
                          <div className='list-container'>
                            <ul>
                              {this.props.sec6.section_6_list_2_rep.map((val, index) => <li className='list-item' key={index}>{val.text}</li>)}
                            </ul>
                          </div>
                        </div>
               </div>
             </Col>
           </Row>
         </Container>
        </div>
      );
    }
  }


export default SectionSix;
