import React, { useState } from 'react';

class SectionTabs extends React.Component {
  constructor(props) {
    super(props);
    this.akordeon = this.akordeon.bind(this);
  }
  akordeon(event) {
       event.preventDefault();
      if (!event.target.classList.contains('accordiontab-toggle')) return;
      var content = document.querySelector(event.target.attributes.test.value);
      var toggler = event.target;
      if (!content) return;

      if(content.classList.contains('active')) {
        console.log('not changed');
      } else {
        var accordions = document.querySelectorAll('.accordiontab-content.active');
        for (var i = 0; i < accordions.length; i++) {
          accordions[i].classList.remove('active');
        }
        setTimeout(function() {
          content.classList.toggle('active');
        }, 200);
      }


      var accordionss = document.querySelectorAll('.accordiontab-toggle.active');
      for (var i = 0; i < accordionss.length; i++) {
        accordionss[i].classList.remove('active');
      }
      toggler.classList.toggle('active');
    }

    getShit(){
      const cards = this.props.cards.map((card, index) => {
       if(index === 0) {
        return ( 
          <>
              <a key={index} href="" test={['#contenttab-' + index]} onClick={(e) => this.akordeon(e)} className="accordiontab-toggle acctab-button tabtitle active">{card.title}</a>
          </>
      );
       } else {
        return ( 
          <>
              <a key={index} href="" test={['#contenttab-' + index]} onClick={(e) => this.akordeon(e)} className="accordiontab-toggle acctab-button tabtitle">{card.title}</a>
          </>
      );
       }
    });
    return cards;
    }

    getShitContent(){
        const cards = this.props.cards.map((card, index) => {
          if(index === 0) {
            return ( 
              <div className='acordeon-contents'>
                  <div className="accordiontab-content active" id={['contenttab-' + index]}>
                      <p>{card.text}</p>
                  </div>
              </div>
          );
          } else {
            return ( 
              <div className='acordeon-contents'>
                  <div className="accordiontab-content" id={['contenttab-' + index]}>
                      <p>{card.text}</p>
                  </div>
              </div>
          );
          }
      });
      return cards;
      }

  render() {

      return (
        <div className='acordion-tabs-box'>
         <div className='accordion-title-box'>
          {this.getShit()}
          </div>
          <div className='acordion-content-box'>          
          {this.getShitContent()}
        </div>
        </div>
      );
    }
  }


export default SectionTabs;