import React from "react"
import { navigate } from 'gatsby'
import { AwesomeButton } from "react-awesome-button";
import ScrollAnimation from 'react-animate-on-scroll';

class SectionBanner extends React.Component {

    render() {
      return (
        <div className="home-slider-content">
          {this.props.lang === 'pl' ?
          <div title={this.props.ban.section_banner_img.alt_text} style={{ 
          backgroundImage: `url(${this.props.ban.section_banner_img.localFile.url})`}} className="background-slider"></div>
          : 
          <div title={this.props.ban.section_banner_img.acf.alt_en} style={{ 
            backgroundImage: `url(${this.props.ban.section_banner_img.localFile.url})`}} className="background-slider"></div>
          }
          <div className="text-on-slider">
          <ScrollAnimation animateOnce='true' animateIn='fadeInUp' duration='0.5'>
          <h3 className='title-small'>{this.props.ban.section_banner_title_small}</h3>
          </ScrollAnimation>
          <ScrollAnimation animateOnce='true' animateIn='fadeInUp' delay='200' duration='0.5'>
          <h1 className='title-large'>{this.props.ban.section_banner_title_large}</h1>
          </ScrollAnimation>
          <ScrollAnimation animateOnce='true' animateIn='fadeInUp' delay='400' offset='100' duration='0.5'>
            {this.props.lang === 'pl' ?
                      <AwesomeButton
                      type="yellow"
                      onPress={() => {
                        navigate('/kontakt')
                      }}
                    >
                    {this.props.ban.section_banner_button}
                    </AwesomeButton>
                    :
                    <AwesomeButton
                    type="yellow"
                    onPress={() => {
                      navigate('/en/contact')
                    }}
                  >
                  {this.props.ban.section_banner_button}
                  </AwesomeButton>
          }

          </ScrollAnimation>

        </div>
         <div className='right-bottom-curve-cont'>
       
          <div className="right-bottom-curve"></div>
       
        </div> 
        </div>
      );

    }
  }


export default SectionBanner;
