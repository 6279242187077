import { Link } from "gatsby"
import React from "react"
import { Container, Row, Col } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';

class SectionOne extends React.Component {
    render() {

      return (
        <div className="section1">
        <Container>
          <Row>
            <Col xl='6' className='title-box'>
            <h2 className='title'>{this.props.field.section_1_title}<span className='dot-yel'>.</span></h2>
       <p className='text'>{this.props.field.section_1_text}</p>
            </Col>
            {this.props.field.section_1_rep.map((val, index) => 
            
            <Col xl='3' md='6' className='box' key={index}>
            <ScrollAnimation animateOnce='true' style={{height:'100%'}} animateIn='fadeInRight' delay={index * 100} duration='0.5'>
              <Link to={'/' + val.link}>

              
              <div className='inner-box'>
              <div className='icon-box'>
                <i className={'icons flaticon-' + val.img_flat}></i>
                </div>
              <h6 className='box-text'>{val.text}</h6>
              <div className='inner-shape'>
                <img src='https://cdn.lemoon-web.pl/wp-content/uploads/2021/05/shape-sec-2.png'></img>
              </div>
              </div>
              
              </Link>
              </ScrollAnimation>
              </Col>
             
            )}
          </Row>
        </Container>
        </div>
      );
    }
  }


export default SectionOne;
