import React from "react"
import { Container, Row, Col } from 'reactstrap';

class SectionFive extends React.Component {

    render() {
      return (
        <div className="section5">
          <div className='section-bg'>
          </div>
         <Container>
           <Row className='tech-row'>
            <Col lg='6'>
              <Row>
            <Col xs='3' className='col-counters'><div className='box-inner'><div className='content'>
            <div className='tech-icon'><img src='https://cdn.lemoon-web.pl/wp-content/uploads/2021/04/prestashop-logo.svg' alt='prestashop-logo'></img></div>
            </div></div></Col>
            <Col xs='3' className='col-counters'><div className='box-inner'><div className='content'>
            <div className='tech-icon'><img src='https://cdn.lemoon-web.pl/wp-content/uploads/2021/04/sass-logo.svg' alt='sass-logo'></img></div>
            </div></div></Col>
            <Col xs='3' className='col-counters'><div className='box-inner'><div className='content'>
            <div className='tech-icon'><img src='https://cdn.lemoon-web.pl/wp-content/uploads/2021/04/graphql-logo.svg' alt='graphql-logo'></img></div>
            </div></div></Col>
            <Col xs='3' className='col-counters'><div className='box-inner'><div className='content'>
            <div className='tech-icon'><img src='https://cdn.lemoon-web.pl/wp-content/uploads/2021/04/sylius-logo.svg' alt='sylius-logo'></img></div>
            </div></div></Col>
            </Row>
            </Col>
           
            <Col lg='6'>
              <Row>
            <Col xs='3' className='col-counters'><div className='box-inner'><div className='content'>
            <div className='tech-icon'><img src='https://cdn.lemoon-web.pl/wp-content/uploads/2021/04/laravel-logo.svg' alt='laravel-logo'></img></div>
            </div></div></Col>
            <Col xs='3' className='col-counters'><div className='box-inner'><div className='content'>
            <div className='tech-icon'><img src='https://cdn.lemoon-web.pl/wp-content/uploads/2021/04/gatsby-logo.svg' alt='gatsby-logo'></img></div>
            </div></div></Col>
            <Col xs='3' className='col-counters'><div className='box-inner'><div className='content'>
            <div className='tech-icon'><img src='https://cdn.lemoon-web.pl/wp-content/uploads/2021/04/html5-logo.svg' alt='html5-logo'></img></div>
            </div></div></Col>
            <Col xs='3' className='col-counters'><div className='box-inner box-inner-last'><div className='content'>
            <div className='tech-icon'><img src='https://cdn.lemoon-web.pl/wp-content/uploads/2021/04/css-3-logo.svg' alt='css3-logo'></img></div>
            </div></div></Col>
            </Row>
            </Col>

            <Col lg='6'>
              <Row>
            <Col xs='3' className='col-counters'><div className='box-inner'><div className='content'>
            <div className='tech-icon'><img src='https://cdn.lemoon-web.pl/wp-content/uploads/2021/04/php-logo.svg' alt='php-logo'></img></div>
            </div></div></Col>
            <Col xs='3' className='col-counters'><div className='box-inner'><div className='content'>
            <div className='tech-icon'><img src='https://cdn.lemoon-web.pl/wp-content/uploads/2021/04/jquery-logo.svg' alt='jquery-logo'></img></div>
            </div></div></Col>
            <Col xs='3' className='col-counters'><div className='box-inner'><div className='content'>
            <div className='tech-icon'><img src='https://cdn.lemoon-web.pl/wp-content/uploads/2021/04/woocommerce-logo.svg' alt='woocommerce-logo'></img></div>
            </div></div></Col>
            <Col xs='3' className='col-counters'><div className='box-inner'><div className='content'>
            <div className='tech-icon'><img src='https://cdn.lemoon-web.pl/wp-content/uploads/2021/04/react-logo.svg' alt='react-logo'></img></div>
            </div></div></Col>
            </Row>
            </Col>
           
            <Col lg='6'>
              <Row>
            <Col xs='3' className='col-counters'><div className='box-inner'><div className='content'>
            <div className='tech-icon'><img src='https://cdn.lemoon-web.pl/wp-content/uploads/2021/04/magento-logo.svg' alt='magento-logo'></img></div>
            </div></div></Col>
            <Col xs='3' className='col-counters'><div className='box-inner'><div className='content'>
            <div className='tech-icon'><img src='https://cdn.lemoon-web.pl/wp-content/uploads/2021/04/js-logo.svg' alt='javascript-logo'></img></div>
            </div></div></Col>
            <Col xs='3' className='col-counters'><div className='box-inner'><div className='content'>
            <div className='tech-icon'><img src='https://cdn.lemoon-web.pl/wp-content/uploads/2021/04/git-logo.svg' alt='git-logo'></img></div>
            </div></div></Col>
            <Col xs='3' className='col-counters'><div className='box-inner box-inner-last'><div className='content'>
            <div className='tech-icon'><img src='https://cdn.lemoon-web.pl/wp-content/uploads/2021/04/wordpress-logo.svg' alt='wordpress-logo'></img></div>
            </div></div></Col>
            </Row>
            </Col>

           </Row>
         </Container>
         
         <Container>
           <Row>
              <Col lg='5' className='cols'>
                  <div className='cloud'>
                    <h3 className='title'>{this.props.sec5.section_5_cloud_text}</h3>
                  </div>
                  {this.props.sec5.section_5_list_rep.map((val, index) => 
                  <div className='cloud-list' key={index}>
                    <div className='count'>0{index + 1}</div>
                    <h5 className='title'>{val.title}</h5>
                    <h6 className='subtitle'>{val.subtitle}</h6>
                  </div>
                )}
              </Col>
              <Col lg='7' className='cols cols-second'>
                    <div className='right-col-inner'>
                        <div className='header'>
                          <h2 className='title'>
                          {this.props.sec5.section_5_title}<span className='dot'>.</span>
                          </h2>
                          <p className='subtitle'>
                          {this.props.sec5.section_5_subtitle}
                          </p>
                        </div>
                        <div className='featured-block'>

                          {this.props.lang === 'pl' ?
                        <div className='image'>
                        <img src={this.props.sec5.section_5_list_2_img.localFile.url} alt={this.props.sec5.section_5_list_2_img.alt_text}></img>
                      </div>
                      :
                      <div className='image'>
                            <img src={this.props.sec5.section_5_list_2_img.localFile.url} alt={this.props.sec5.section_5_list_2_img.acf.alt_en}></img>
                          </div>  
                        }

                          <div className='list-container'>
                            <ul>
                              {this.props.sec5.section_5_list_2_rep.map((val, index) => <li className='list-item' key={index}>{val.text}</li>)}
                            </ul>
                          </div>
                        </div>
                    </div>
              </Col>
           </Row>
         </Container>
        </div>
      );
    }
  }


export default SectionFive;
