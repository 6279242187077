import React from "react"
import { navigate } from 'gatsby'
import { Container, Row, Col } from 'reactstrap';
import { AwesomeButton } from "react-awesome-button";
import ScrollAnimation from 'react-animate-on-scroll';


class SectionTwo extends React.Component {
    render() {
      return (
        <div className="section2">
          <div className='bg-grey'>
          </div>
        <Container>
          <Row>
          {this.props.lang === 'pl' ?
            <Col xl='6' className='images'>
              <div className='inner'>
              <ScrollAnimation animateOnce='true' animateIn='fadeInUp'>
                <div className='img-behind-cont'>            
                <img className='image-behind' src={this.props.sec2.section_2_img_behind.localFile.url} alt={this.props.sec2.section_2_img_behind.alt_text}></img>
                </div>
                </ScrollAnimation>
                <ScrollAnimation animateOnce='true' animateIn='fadeInLeft'>
                <div className='img-front-cont'>
                <img className='image-front' src={this.props.sec2.section_2_img_front.localFile.url} alt={this.props.sec2.section_2_img_front.alt_text}></img>
                </div>
                </ScrollAnimation>
              </div>
            </Col>
            :
            <Col xl='6' className='images'>
            <div className='inner'>
            <ScrollAnimation animateOnce='true' animateIn='fadeInUp'>
              <div className='img-behind-cont'>            
              <img className='image-behind' src={this.props.sec2.section_2_img_behind.localFile.url} alt={this.props.sec2.section_2_img_behind.acf.alt_en}></img>
              </div>
              </ScrollAnimation>
              <ScrollAnimation animateOnce='true' animateIn='fadeInLeft'>
              <div className='img-front-cont'>
              <img className='image-front' src={this.props.sec2.section_2_img_front.localFile.url} alt={this.props.sec2.section_2_img_front.acf.alt_en}></img>
              </div>
              </ScrollAnimation>
            </div>
          </Col>
           }

            <Col xl='6' className='text-container'>
              <div className='inner'>
              <h2 className="title">{this.props.sec2.section_2_title}</h2>
              <h3 className="subtitle">{this.props.sec2.section_2_subtitle}</h3>
              <p className='text'>{this.props.sec2.section_2_text}</p>
              <div className='text-contain clearfix'>
              <ul>
              {this.props.sec2.section_2_list_rep.map((val, index) => <li className='list-item' key={index}>{val.text}</li>)}
              </ul>

              {/* <div className='list-sign'>
                <span className='text'>
                {this.props.sec2.section_2_list_sign}
                </span>
              </div> */}

              </div>
                {this.props.lang === 'pl' ?
                <div className='button-cont'>
                <AwesomeButton
                  type="primary"
                  onPress={() => {
                    navigate('/kontakt')
                  }}
                >
                {this.props.sec2.section_2_button}
                </AwesomeButton>
              </div>
              : 
              <div className='button-cont'>
                  <AwesomeButton
                    type="primary"
                    onPress={() => {
                      navigate('/en/contact')
                    }}
                  >
                  {this.props.sec2.section_2_button}
                  </AwesomeButton>
                </div>
              }
                
              </div>
            </Col>
         </Row> 
        </Container>
        </div>
      );
    }
  }


export default SectionTwo;
