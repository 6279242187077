import React, { useState } from 'react';

class SectionAkord extends React.Component {
  constructor(props) {
    super(props);
    
    this.akordeon = this.akordeon.bind(this);
  }
  akordeon(event) {
       event.preventDefault();
      if (!event.target.classList.contains('accordion-toggle')) return;
  
      var content = document.querySelector(event.target.attributes.test.value);
      var toggler = event.target;
      if (!content) return;
      
      // if (content.classList.contains('active')) {
      //   content.classList.remove('active');
      //   toggler.classList.remove('active');
      //   return;
      // }


      if(content.classList.contains('active')) {
      } else {
        var accordions = document.querySelectorAll('.accordion-content.active');
        for (var i = 0; i < accordions.length; i++) {
          accordions[i].classList.remove('active');
        }
          content.classList.toggle('active');
      }


      
      // var accordions = document.querySelectorAll('.accordion-content.active');
      // for (var i = 0; i < accordions.length; i++) {
      //   accordions[i].classList.remove('active');
      // }
      // content.classList.toggle('active');

      var accordionss = document.querySelectorAll('.accordion-toggle.active');
      for (var i = 0; i < accordionss.length; i++) {
        accordionss[i].classList.remove('active');
      }
      toggler.classList.toggle('active');
    }

    getShit(){
      const cards = this.props.cards.map((card, index) => {
        if(index === 0) {
        return (  
        <div className="akordeoncontainer" key={index}> 
        <a href="" onClick={(e) => this.akordeon(e)} test={['#content-' + index]} className="accordion-toggle acc-button active"><div className='count'>{index + 1}.</div>{card.title}</a>
          <div className="accordion-content active" id={['content-' + index]}>
         <p>{card.text}</p>
          </div>
        </div>
        );
        }else{
          return ( 
            <div className="akordeoncontainer" key={index}> 
            <a href="" onClick={(e) => this.akordeon(e)} test={['#content-' + index]} className="accordion-toggle acc-button"><div className='count'>{index + 1}.</div>{card.title}</a>
              <div className="accordion-content" id={['content-' + index]}>
             <p>{card.text}</p>
              </div>
            </div>
            );
        }
    });
    return cards;
    }
  render() {

      return (
        
         <div className='accordion-box'>
          {this.getShit()}

      </div>
      );
    }
  }


export default SectionAkord;